<template>
  <ClFormRow :label="label" :toolTipText="tooltip">
    <template #content>
      <multiselect
        @input="onChangeTrans"
        v-model="transformerId"
        :options="transformerOptions"
        :searchable="false"
        :multiple="false"
        :allow-empty="false"
        :block-keys="['Tab', 'Enter']"
        required
        :class="selectTransformerErrorClass"
        :selectLabel="selectLabel"
        :deselectLabel="tagPlaceholder"
        class="
          custom-multi-select
          zq--multiselect
          zq--multiselect__select
          zq--multiselect__green
        "
      />
    </template>
  </ClFormRow>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ClFormRow from '@/shared/components/formComponents/ClFormRow';
import { formConfig } from '@/config';
export default {
  components: {
    ClFormRow,
  },
  props: {
    value: [Array, String],
    label: String,
    tooltip: String,
    css: [String, Object],
    multiple: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
  },
  data() {
    return {
      transformerId: null,
      transformerOptions: [],
      transformers: [],
      selectLabel: formConfig.selectLabel,
      tagPlaceholder: formConfig.tagPlaceholder,
      removeLabel: formConfig.removeLabel,
      sortBy: [
        {
          queryField: 'name',
          order: 'Asc',
        },
      ],
      page: 100,
    };
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    selectTransformerErrorClass() {
      const className =
        this.theme === 'main'
          ? 'error-validation--multiselect'
          : 'error-validation';
      return {
        [className]: this.entityValidate === false,
      };
    },
  },
  watch: {},
  methods: {
    ...mapActions('transformers', ['handleGetTransformers']),
    async getTransformers() {
      const data = await this.handleGetTransformers({
        sortBy: [],
        skip: 0,
        limit: 100,
      });
      for (let i in data) {
        this.transformers.push(data[i]);
        this.transformerOptions.push(data[i].name);
      }
    },
    onChangeTrans(value) {
      const find = this.transformers.find((x) => x.name === value);
      this.$emit('input', find.id);
    },
  },
  async created() {
    await this.getTransformers();
    if (this.value) {
      const find = this.transformers.find((x) => x.id === this.value);
      this.transformerId = find ? find?.name : this.value;
    }
  },
};
</script>
